body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.tourism .static-hero {
  padding-top: 50px;
  background-image: url("/static/home/tourism/img-tourism-hero.jpg"); }
  @media (min-width: 768px) {
    .tourism .static-hero {
      height: 450px; } }
  @media (min-width: 1280px) {
    .tourism .static-hero {
      height: 325px; } }
  .tourism .static-hero__heading {
    line-height: 1.5em;
    letter-spacing: 1px; }
    @media (min-width: 768px) {
      .tourism .static-hero__heading {
        font-size: 32px; } }
    @media (min-width: 1280px) {
      .tourism .static-hero__heading {
        font-size: 30px; } }
  @media (min-width: 768px) {
    .tourism .static-hero__subheading {
      font-size: 22px; } }
  @media (min-width: 1280px) {
    .tourism .static-hero__subheading {
      font-size: 19px;
      max-width: 580px; } }

.tourism .why-ep {
  padding-top: 32px;
  padding-bottom: 48px;
  background-color: #F9F9F9; }
  @media (min-width: 768px) {
    .tourism .why-ep {
      padding-top: 37px; } }
  @media (min-width: 1280px) {
    .tourism .why-ep {
      padding-left: 0;
      padding-right: 0;
      padding-top: 42px;
      padding-bottom: 70px; } }
  .tourism .why-ep__heading {
    text-align: center;
    margin-bottom: 32px; }
    @media (min-width: 768px) {
      .tourism .why-ep__heading {
        font-size: 27px;
        margin-bottom: 40px; } }
    @media (min-width: 1280px) {
      .tourism .why-ep__heading {
        font-size: 22px; } }
  .tourism .why-ep__container {
    max-width: 1280px;
    margin: 0 auto; }
    @media (min-width: 1280px) {
      .tourism .why-ep__container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; } }
    .tourism .why-ep__container > svg {
      margin-bottom: 28px; }
      @media (min-width: 768px) {
        .tourism .why-ep__container > svg {
          margin-bottom: 50px; } }
      @media (min-width: 1280px) {
        .tourism .why-ep__container > svg {
          margin: 0;
          width: 650px; } }
  .tourism .why-ep__list {
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 768px) {
      .tourism .why-ep__list {
        padding-left: 0; } }
    @media (min-width: 1280px) {
      .tourism .why-ep__list {
        width: 39%;
        padding-top: 3px;
        margin-right: 35px; } }
  .tourism .why-ep__list-item {
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .tourism .why-ep__list-item {
        margin-bottom: 25px; } }
    @media (min-width: 1280px) {
      .tourism .why-ep__list-item {
        margin-bottom: 10px; } }
  .tourism .why-ep__item-heading {
    margin-bottom: 20px;
    position: relative;
    font-size: 17px; }
    @media (min-width: 768px) {
      .tourism .why-ep__item-heading {
        font-size: 22px;
        margin-bottom: 2px; } }
    @media (min-width: 1280px) {
      .tourism .why-ep__item-heading {
        font-size: 18px; } }
    .tourism .why-ep__item-heading::after {
      background-color: #008aae;
      transform: translateX(0);
      left: 0;
      bottom: -11px;
      width: 28px;
      height: 2px; }
      @media (min-width: 768px) {
        .tourism .why-ep__item-heading::after {
          display: none; } }
  @media (min-width: 768px) {
    .tourism .why-ep__item-desc {
      font-size: 16px;
      line-height: 1.6em; } }
  @media (min-width: 1280px) {
    .tourism .why-ep__item-desc {
      font-size: 13px; } }

.tourism .tourism-carousel .carousel {
  max-width: 768px; }
  @media (min-width: 1280px) {
    .tourism .tourism-carousel .carousel {
      max-width: 1280px; } }
  .tourism .tourism-carousel .carousel__arrows {
    padding: 0 38%;
    top: 75vw; }
    @media (min-width: 479px) {
      .tourism .tourism-carousel .carousel__arrows {
        top: 72vw; } }
    @media (min-width: 768px) {
      .tourism .tourism-carousel .carousel__arrows {
        top: 83%;
        padding: 0 9%; } }
    @media (min-width: 1280px) {
      .tourism .tourism-carousel .carousel__arrows {
        top: 58%;
        padding: 0;
        width: 380px;
        right: 120px; } }
  .tourism .tourism-carousel .carousel__arrow {
    width: 12px;
    height: 12px;
    border-left: 2px solid #CCCCCC;
    border-bottom: 2px solid #CCCCCC; }
    .tourism .tourism-carousel .carousel__arrow--prev {
      transform: rotate(45deg); }
    .tourism .tourism-carousel .carousel__arrow--next {
      transform: rotate(-135deg); }
  .tourism .tourism-carousel .carousel__slides {
    text-align: center;
    position: relative; }
    .tourism .tourism-carousel .carousel__slides > .owl-dots {
      position: absolute;
      top: 75vw;
      left: 50%;
      transform: translateX(-50%);
      height: 10px;
      display: flex;
      justify-content: space-between;
      width: 35px;
      margin: 0 auto; }
      @media (min-width: 479px) {
        .tourism .tourism-carousel .carousel__slides > .owl-dots {
          top: 72vw; } }
      @media (min-width: 768px) {
        .tourism .tourism-carousel .carousel__slides > .owl-dots {
          top: 63%;
          left: auto;
          right: 66px;
          width: 53px; } }
      @media (min-width: 1280px) {
        .tourism .tourism-carousel .carousel__slides > .owl-dots {
          top: 93%;
          left: auto;
          right: 45%;
          width: 38px; } }
      .tourism .tourism-carousel .carousel__slides > .owl-dots > .owl-dot {
        height: 8px;
        width: 8px;
        border: 2px solid #CCCCCC;
        border-radius: 50%; }
        @media (min-width: 768px) {
          .tourism .tourism-carousel .carousel__slides > .owl-dots > .owl-dot {
            width: 12px;
            height: 12px;
            border: 1px solid #CCCCCC; } }
        @media (min-width: 1280px) {
          .tourism .tourism-carousel .carousel__slides > .owl-dots > .owl-dot {
            width: 8px;
            height: 8px; } }
        .tourism .tourism-carousel .carousel__slides > .owl-dots > .owl-dot.active {
          background-color: #CCCCCC; }
  @media (min-width: 768px) {
    .tourism .tourism-carousel .carousel__slide {
      padding-top: 83px; } }
  @media (min-width: 1280px) {
    .tourism .tourism-carousel .carousel__slide {
      display: flex;
      justify-content: space-between;
      padding-top: 145px; } }
  .tourism .tourism-carousel .carousel__images {
    position: relative; }
    @media (min-width: 768px) {
      .tourism .tourism-carousel .carousel__images {
        padding-top: 50px;
        margin-bottom: 120px; } }
    @media (min-width: 1280px) {
      .tourism .tourism-carousel .carousel__images {
        margin-left: 57px;
        margin-bottom: 0; } }
  .tourism .tourism-carousel .carousel__img {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    .tourism .tourism-carousel .carousel__img--top {
      margin-bottom: 60px; }
      @media (min-width: 768px) {
        .tourism .tourism-carousel .carousel__img--top {
          max-width: 535px;
          height: auto;
          z-index: 2;
          position: relative;
          margin-left: auto;
          margin-right: 75px; } }
      @media (min-width: 1280px) {
        .tourism .tourism-carousel .carousel__img--top {
          max-width: 500px;
          margin-left: 140px;
          margin-right: 0; } }
    .tourism .tourism-carousel .carousel__img--bottom {
      display: none; }
      @media (min-width: 768px) {
        .tourism .tourism-carousel .carousel__img--bottom {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          max-width: 345px;
          height: auto;
          z-index: 0;
          margin-left: 65px; } }
      @media (min-width: 1280px) {
        .tourism .tourism-carousel .carousel__img--bottom {
          max-width: 318px; } }
  @media (min-width: 768px) {
    .tourism .tourism-carousel .carousel__container {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 1280px) {
    .tourism .tourism-carousel .carousel__container {
      padding: 114px 0 0 0;
      margin-right: 154px;
      max-width: 307px; } }
  .tourism .tourism-carousel .carousel__title {
    font-size: 17px;
    margin-bottom: 7px; }
    @media (min-width: 768px) {
      .tourism .tourism-carousel .carousel__title {
        font-size: 27px;
        margin-bottom: 16px;
        letter-spacing: .5px; } }
    @media (min-width: 1280px) {
      .tourism .tourism-carousel .carousel__title {
        font-size: 20px;
        text-align: left;
        margin-bottom: 18px;
        letter-spacing: 1px; } }
  .tourism .tourism-carousel .carousel__description {
    color: #808080;
    line-height: 1.8em;
    margin-bottom: 35px; }
    @media (min-width: 768px) {
      .tourism .tourism-carousel .carousel__description {
        font-size: 16px;
        line-height: 1.45em; } }
    @media (min-width: 1280px) {
      .tourism .tourism-carousel .carousel__description {
        font-size: 13px;
        text-align: justify;
        line-height: 1.7em;
        margin-bottom: 24px; } }
  @media (min-width: 768px) {
    .tourism .tourism-carousel .carousel__footer {
      display: flex;
      justify-content: space-between; } }
  .tourism .tourism-carousel .carousel__info {
    font-size: 10px;
    color: #b3b3b3;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    max-width: 160px; }
    @media (min-width: 768px) {
      .tourism .tourism-carousel .carousel__info {
        font-size: 14px;
        margin-left: 0;
        max-width: 210px;
        text-align: left;
        line-height: 1.3em; } }
    @media (min-width: 1280px) {
      .tourism .tourism-carousel .carousel__info {
        font-size: 9px;
        padding-right: 30px; } }
  @media (min-width: 768px) {
    .tourism .tourism-carousel .carousel__button {
      padding: 6px 45px; } }
  @media (min-width: 1280px) {
    .tourism .tourism-carousel .carousel__button {
      padding: 4px 24px;
      font-size: 10px; } }

.tourism .ep-for-hotels {
  margin-bottom: 48px; }
  @media (min-width: 768px) {
    .tourism .ep-for-hotels {
      margin-bottom: 12px; } }

.tourism .ep-for-events {
  margin-bottom: 57px; }
  @media (min-width: 768px) {
    .tourism .ep-for-events {
      margin-bottom: 130px; } }
  @media (min-width: 1280px) {
    .tourism .ep-for-events {
      margin-bottom: 105px; } }
  @media (min-width: 768px) {
    .tourism .ep-for-events .carousel__slides > .owl-dots {
      left: 107px;
      right: auto; } }
  @media (min-width: 1280px) {
    .tourism .ep-for-events .carousel__slides > .owl-dots {
      left: 48%; } }
  @media (min-width: 1280px) {
    .tourism .ep-for-events .carousel__arrows {
      right: auto;
      left: 110px; } }
  .tourism .ep-for-events .carousel__slide {
    flex-direction: row-reverse; }
  @media (min-width: 1280px) {
    .tourism .ep-for-events .carousel__images {
      margin-right: 122px; } }
  @media (min-width: 768px) {
    .tourism .ep-for-events .carousel__img--top {
      margin-left: 62px;
      margin-right: auto; } }
  @media (min-width: 1280px) {
    .tourism .ep-for-events .carousel__img--top {
      margin-left: 0;
      margin-right: 76px; } }
  .tourism .ep-for-events .carousel__img--bottom {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: 72px; }
    @media (min-width: 1280px) {
      .tourism .ep-for-events .carousel__img--bottom {
        margin-right: 0; } }
  .tourism .ep-for-events .carousel__container {
    margin-right: 72px; }

.tourism .example {
  background-color: #F4F4F4;
  text-align: center;
  padding-top: 28px;
  padding-bottom: 50px; }
  @media (min-width: 768px) {
    .tourism .example {
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media (min-width: 1280px) {
    .tourism .example {
      padding-top: 70px;
      padding-bottom: 140px; } }
  .tourism .example__heading {
    margin-bottom: 27px;
    font-weight: 700; }
    @media (min-width: 768px) {
      .tourism .example__heading {
        font-size: 27px;
        margin-bottom: 65px; } }
    @media (min-width: 1280px) {
      .tourism .example__heading {
        font-size: 30px;
        margin-bottom: 108px; } }
  .tourism .example__wrapper {
    max-width: 768px;
    margin: 0 auto; }
    @media (min-width: 1280px) {
      .tourism .example__wrapper {
        display: flex;
        align-items: flex-start;
        max-width: 1280px;
        padding-left: 220px;
        padding-right: 200px; } }
  .tourism .example__img {
    max-width: 100%;
    margin-bottom: 28px; }
    @media (min-width: 768px) {
      .tourism .example__img {
        margin-bottom: 25px; } }
    @media (min-width: 1280px) {
      .tourism .example__img {
        max-width: 420px; } }
  @media (min-width: 768px) {
    .tourism .example__container {
      padding-left: 130px;
      padding-right: 130px; } }
  @media (min-width: 1280px) {
    .tourism .example__container {
      padding-left: 58px;
      padding-right: 0;
      padding-top: 20px;
      text-align: left; } }
  .tourism .example__logo {
    display: block;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .tourism .example__logo {
        width: 90px; } }
    @media (min-width: 1280px) {
      .tourism .example__logo {
        margin-left: 0; } }
  .tourism .example__title {
    color: #333333;
    margin-bottom: 10px;
    font-weight: 700; }
    @media (min-width: 768px) {
      .tourism .example__title {
        margin-bottom: 34px;
        font-size: 27px; } }
    @media (min-width: 1280px) {
      .tourism .example__title {
        font-size: 18px;
        text-align: left;
        margin-bottom: 10px; } }
  .tourism .example__description {
    line-height: 1.4em;
    margin-bottom: 15px;
    text-align: justify; }
    @media (min-width: 768px) {
      .tourism .example__description {
        font-size: 16px;
        margin-bottom: 30px; } }
    @media (min-width: 1280px) {
      .tourism .example__description {
        font-size: 13px;
        margin-bottom: 22px; } }
