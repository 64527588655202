.carousel-container {
  overflow: visible;
  position: relative; }
  .carousel-container .carousel {
    padding-top: 40px;
    padding-bottom: 40px; }
  .carousel-container .home-slider-navigation {
    position: absolute;
    z-index: 2;
    top: 46px; }
    .carousel-container .home-slider-navigation ul {
      margin: 0;
      padding-left: 120px;
      font-size: 0; }
      .carousel-container .home-slider-navigation ul li {
        list-style: none;
        display: inline-block;
        vertical-align: middle;
        padding: 0 17px; }
        .carousel-container .home-slider-navigation ul li:first-child {
          padding-left: 0; }
        .carousel-container .home-slider-navigation ul li:last-child {
          padding-right: 0; }
        .carousel-container .home-slider-navigation ul li.active a, .carousel-container .home-slider-navigation ul li:hover a {
          transition: all 150ms ease-in-out;
          color: #000;
          border-color: #000;
          border-width: 2px;
          -webkit-transform: scale(1);
          transform: scale(1); }
      .carousel-container .home-slider-navigation ul:after {
        content: "";
        position: absolute;
        height: 2px;
        right: 5px;
        left: 0;
        background: #ddd;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        top: 50%;
        z-index: -1; }
    .carousel-container .home-slider-navigation a {
      display: inline-block;
      border: 3px solid #ddd;
      font-family: Roboto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 300;
      transition: all 150ms ease-in-out;
      font-size: 13px;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      line-height: 20px;
      color: #fbfbfb;
      font-weight: 700;
      text-align: center;
      background: #fbfbfb;
      z-index: 3;
      -webkit-transform: scale(0.5);
      transform: scale(0.5); }
  .carousel-container .arrows {
    position: absolute;
    z-index: 3;
    padding-left: 19px;
    bottom: 72px; }
    .carousel-container .arrows svg {
      cursor: pointer;
      height: 20px; }
    .carousel-container .arrows .prev {
      margin-right: 51px; }
      .carousel-container .arrows .prev svg {
        transition: all 150ms ease-in-out; }
      .carousel-container .arrows .prev:hover svg {
        transition: all 150ms ease-in-out;
        -webkit-transform: translate(-5px, -5px);
        transform: translate(-5px, -5px); }
    .carousel-container .arrows .next svg {
      transition: all 150ms ease-in-out; }
    .carousel-container .arrows .next:hover svg {
      transition: all 150ms ease-in-out;
      -webkit-transform: translate(5px, 5px);
      transform: translate(5px, 5px); }
  .carousel-container .slide {
    display: flex !important;
    flex-direction: column !important; }
    .carousel-container .slide h4 {
      font-weight: 400;
      font-size: 30px;
      margin-top: 0;
      margin-bottom: 35px;
      font-family: Roboto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .carousel-container .slide p {
      font-family: Roboto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 30px;
      margin-bottom: 30px;
      font-size: 18px;
      color: #676767;
      font-weight: 300; }
    .carousel-container .slide .btn {
      max-width: 190px;
      display: inline-block;
      border: 2px solid #000;
      width: 146px;
      height: 45px;
      line-height: 42px;
      border-radius: 20px;
      transition: all 150ms ease-in-out;
      font-size: 13px;
      text-transform: uppercase;
      margin-top: 10px;
      background-color: #000;
      color: #ffff41;
      letter-spacing: 1.1px;
      font-family: Roboto;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center; }
      .carousel-container .slide .btn:hover {
        transition: all 150ms ease-in-out;
        background-color: #ffff41;
        border-color: #ffff41;
        color: #000; }
    .carousel-container .slide .large-8.columns {
      padding-right: 0; }
    .carousel-container .slide[data-slide="1"], .carousel-container .slide[data-slide="2"] {
      background-position: 0;
      background-size: 300px;
      background-repeat: no-repeat;
      margin-bottom: 50px; }
    .carousel-container .slide[data-slide="3"] {
      background-position: 0;
      background-size: 300px;
      background-repeat: no-repeat; }
  .carousel-container .arrows .next,
  .carousel-container .arrows .prev {
    width: 17px;
    height: 17px;
    float: left; }
  .carousel-container .arrows svg {
    stroke: #000;
    float: left;
    width: 100%; }

@media only screen and (max-width: 48em) {
  .carousel-container .carousel .slide .columns {
    padding-left: 0;
    padding-right: 0; } }

@media only screen and (min-width: 64em) and (max-width: 1550px) {
  .carousel-container .slide p {
    font-size: 15px; }
  .carousel-container .arrows {
    bottom: 35px; } }

@media only screen and (min-width: 1px) and (max-width: 64em) {
  .row.carousel-container .home-slider-navigation {
    width: 100%;
    text-align: center; }
    .row.carousel-container .home-slider-navigation ul {
      padding-left: 0; }
  .row.carousel-container .carousel .owl-item {
    overflow: hidden; }
  .row.carousel-container .vc {
    padding-top: 40px; }
  .row.carousel-container .slide .large-8.columns,
  .row.carousel-container .slide .large-9 {
    padding: 0; }
  .row.carousel-container .arrows {
    text-align: center;
    padding: 0;
    bottom: 45px; }
    .row.carousel-container .arrows .next {
      float: none;
      display: inline-block; }
    .row.carousel-container .arrows .prev {
      float: none;
      display: inline-block;
      margin-right: 85px; }
  .row.carousel-container [data-slide] {
    background-position: 0 100% !important; } }

@media only screen and (max-width: 48em) {
  .row.carousel-container {
    margin-bottom: 18px;
    float: none; }
    .row.carousel-container [data-slide] {
      background-position: 100% 100%; }
    .row.carousel-container .slide {
      text-align: center; }
      .row.carousel-container .slide h4 {
        font-size: 25px; }
      .row.carousel-container .slide p {
        font-size: 14px;
        line-height: 30px;
        margin-bottom: 29px; } }
