.trainings-slide-show {
  display: -webkit-flex;
  align-items: center;
  margin: auto;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden; }
  .trainings-slide-show .slide-show__inner {
    margin: 0 auto;
    height: 100%; }
  .trainings-slide-show .slide-show__item {
    height: 100%;
    width: 100%; }
