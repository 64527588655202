body::before {
  content: "(xsm: 479px, sm: 750px, md: 768px, lg: 970px, xl: 1280px)";
  display: none; }

.real-estate-tabs {
  display: flex;
  max-width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #CCCCCC;
  min-height: 400px; }

.real-estate .why-use {
  padding-top: 34px;
  padding-bottom: 35px;
  max-width: 1280px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .real-estate .why-use {
      padding-top: 40px;
      padding-bottom: 98px; } }
  @media (min-width: 1280px) {
    .real-estate .why-use {
      padding-top: 40px;
      padding-bottom: 0; } }
  .real-estate .why-use__heading {
    text-align: center; }
    @media (min-width: 1280px) {
      .real-estate .why-use__heading {
        font-size: 30px; } }
  .real-estate .why-use__container {
    width: 100%; }
    @media (min-width: 1280px) {
      .real-estate .why-use__container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 35px; } }
  .real-estate .why-use__img {
    display: block;
    width: 100%;
    max-width: 750px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px; }
    @media (min-width: 768px) {
      .real-estate .why-use__img {
        margin-bottom: 103px;
        margin-top: 25px; } }
    @media (min-width: 1280px) {
      .real-estate .why-use__img {
        width: 75%;
        margin-left: -110px;
        margin-bottom: 3px;
        margin-top: 25px;
        max-width: none; } }
  .real-estate .why-use__content {
    width: 100%; }
    @media (min-width: 1280px) {
      .real-estate .why-use__content {
        width: 40%;
        padding: 35px 105px 0 10px; } }
  .real-estate .why-use__content-box {
    padding: 0 33px;
    margin-bottom: 32px; }
    .real-estate .why-use__content-box:last-child {
      margin-bottom: 0; }
    @media (min-width: 768px) {
      .real-estate .why-use__content-box {
        padding: 0 7px;
        margin-bottom: 77px; } }
    @media (min-width: 1280px) {
      .real-estate .why-use__content-box {
        margin-bottom: 20px; } }
  .real-estate .why-use__box-heading {
    position: relative;
    margin-bottom: 26px; }
    @media (min-width: 768px) {
      .real-estate .why-use__box-heading {
        margin-bottom: 56px; } }
    @media (min-width: 1280px) {
      .real-estate .why-use__box-heading {
        margin-bottom: 30px; } }
    .real-estate .why-use__box-heading::after {
      left: 0;
      bottom: -12px;
      height: 2px;
      width: 27px;
      transform: translateX(0); }
      @media (min-width: 768px) {
        .real-estate .why-use__box-heading::after {
          width: 63px;
          height: 3px;
          bottom: -28px; } }
      @media (min-width: 1280px) {
        .real-estate .why-use__box-heading::after {
          width: 37px;
          height: 2px;
          bottom: -16px; } }

.real-estate .asari {
  background-color: #008aae;
  padding: 34px;
  background-image: url("/static/home/real-estate/background.jpg");
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .real-estate .asari {
      padding: 40px; } }
  .real-estate .asari__title {
    font-weight: 500;
    color: #FFF100;
    text-align: center; }
    @media (min-width: 1280px) {
      .real-estate .asari__title {
        font-size: 30px; } }
  @media (min-width: 1280px) {
    .real-estate .asari__box_container {
      max-width: 735px; } }
  .real-estate .asari__box {
    margin-top: 40px; }
    @media (min-width: 768px) {
      .real-estate .asari__box {
        margin-top: 50px; } }
    @media (min-width: 1280px) {
      .real-estate .asari__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1280px; } }
    .real-estate .asari__box__subtitle {
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      position: relative;
      margin-bottom: 26px; }
      @media (min-width: 768px) {
        .real-estate .asari__box__subtitle {
          margin-left: 13px;
          text-align: left;
          margin-bottom: 56px; } }
      @media (min-width: 1280px) {
        .real-estate .asari__box__subtitle {
          margin-left: 22px;
          margin-bottom: 30px; } }
      .real-estate .asari__box__subtitle::after {
        content: '';
        display: block;
        width: 27px;
        height: 2px;
        background-color: #FFF100;
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%); }
        @media (min-width: 768px) {
          .real-estate .asari__box__subtitle::after {
            width: 63px;
            height: 3px;
            bottom: -28px;
            left: 33px; } }
        @media (min-width: 1280px) {
          .real-estate .asari__box__subtitle::after {
            width: 37px;
            height: 2px;
            bottom: -16px;
            left: 16px; } }
    .real-estate .asari__box .list-item-desc {
      color: #ffffff;
      text-align: center;
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        .real-estate .asari__box .list-item-desc {
          text-align: left;
          margin-left: 13px; } }
      @media (min-width: 1280px) {
        .real-estate .asari__box .list-item-desc {
          margin-left: 22px;
          margin-bottom: 10px; } }
    .real-estate .asari__box .image {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .real-estate .asari__box .image img {
        width: 70vw; }
        @media (min-width: 1280px) {
          .real-estate .asari__box .image img {
            width: 37vw; } }
  .real-estate .asari__box-list {
    max-width: 870px; }
    @media (min-width: 768px) {
      .real-estate .asari__box-list {
        padding-left: 46px;
        padding-right: 30px;
        margin: 0 auto; } }
    @media (min-width: 1280px) {
      .real-estate .asari__box-list {
        margin: 0;
        margin-bottom: 43px; } }
  .real-estate .asari__box-item {
    padding: 25px;
    padding-left: 30px; }
    .real-estate .asari__box-item:last-child {
      margin-bottom: 0; }
  .real-estate .asari__list-item-desc {
    color: #ffffff;
    line-height: 1.5em; }
    @media (min-width: 768px) {
      .real-estate .asari__list-item-desc {
        margin-top: 20px;
        line-height: 1.8em;
        font-size: 18px; } }
    @media (min-width: 1280px) {
      .real-estate .asari__list-item-desc {
        margin: 0;
        line-height: 1.6em;
        font-size: 13px; } }
  .real-estate .asari__item-heading {
    position: relative;
    margin-bottom: 5px;
    color: #FFF100; }
    .real-estate .asari__item-heading::before {
      content: '';
      display: block;
      width: 16px;
      height: 11px;
      position: absolute;
      top: 3px;
      left: -30px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; }
      @media (min-width: 768px) {
        .real-estate .asari__item-heading::before {
          width: 42px;
          height: 30px;
          top: 3px;
          left: -69px; } }
      @media (min-width: 1280px) {
        .real-estate .asari__item-heading::before {
          width: 35px;
          height: 25px;
          left: -59px; } }
    .real-estate .asari__item-heading--1-1::before {
      background-image: url("/static/home/real-estate/asari/pic-asari-publish.svg"); }
    .real-estate .asari__item-heading--1-2::before {
      background-image: url("/static/home/real-estate/asari/pic-asari-export.svg"); }
    .real-estate .asari__item-heading--1-3::before {
      background-image: url("/static/home/real-estate/asari/pic-asari-manage.svg"); }
    .real-estate .asari__item-heading--1-4::before {
      background-image: url("/static/home/real-estate/asari/pic-asari-browser.svg"); }
  .real-estate .asari__cta {
    text-align: center;
    margin: 30px; }
    @media (min-width: 1280px) {
      .real-estate .asari__cta {
        margin: 0; } }
  @media (min-width: 1280px) {
    .real-estate .asari__button {
      padding-left: 25px;
      padding-right: 25px; } }

.real-estate .for-who {
  padding-top: 30px;
  padding-bottom: 10px; }
  @media (min-width: 768px) {
    .real-estate .for-who {
      padding-top: 130px; } }
  @media (min-width: 1280px) {
    .real-estate .for-who {
      padding-left: 0;
      padding-right: 0;
      padding-top: 84px;
      padding-bottom: 55px; } }
  .real-estate .for-who__box {
    margin-bottom: 22px; }
    @media (min-width: 768px) {
      .real-estate .for-who__box {
        margin-bottom: 58px; } }
    @media (min-width: 1280px) {
      .real-estate .for-who__box {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 29px; } }
    @media (min-width: 1280px) {
      .real-estate .for-who__box--reversed {
        flex-direction: row-reverse;
        margin-bottom: 80px; }
        .real-estate .for-who__box--reversed .for-who__img {
          margin: 0;
          margin-top: 43px;
          margin-right: 0 !important; } }
    @media (min-width: 1280px) and (min-width: 1280px) {
      .real-estate .for-who__box--reversed .for-who__img {
        margin-right: -120px !important; } }
    @media (min-width: 1280px) {
        .real-estate .for-who__box--reversed .for-who__box-content {
          margin-left: 60px; }
        .real-estate .for-who__box--reversed .for-who__box-list {
          padding-left: 34px;
          padding-right: 34px;
          margin-bottom: 38px; } }
    @media (min-width: 1280px) and (min-width: 768px) {
      .real-estate .for-who__box--reversed .for-who__box-list {
        padding-left: 46px;
        padding-right: 30px;
        margin-bottom: 115px; } }
    @media (min-width: 1280px) and (min-width: 1280px) {
      .real-estate .for-who__box--reversed .for-who__box-list {
        margin-bottom: 43px; } }
    .real-estate .for-who__box > .browser {
      margin-bottom: 20px;
      max-width: 650px;
      display: block;
      margin-left: auto;
      margin-right: auto; }
      @media (min-width: 768px) {
        .real-estate .for-who__box > .browser {
          margin-bottom: 49px; } }
      @media (min-width: 1280px) {
        .real-estate .for-who__box > .browser {
          flex-basis: 46%;
          margin-top: 49px;
          margin-right: unset;
          margin-left: unset; } }
  .real-estate .for-who__img {
    align-self: center;
    margin: 0 !important;
    height: calc((100vw/3)*1.7); }
    .real-estate .for-who__img img {
      width: 100%; }
    @media (min-width: 768px) {
      .real-estate .for-who__img {
        width: 99%; } }
    @media (min-width: 1280px) {
      .real-estate .for-who__img {
        width: 60%;
        height: 450px;
        margin: 0 !important; } }
    .real-estate .for-who__img--googles {
      max-width: 100%; }
    .real-estate .for-who__img--smartphones {
      max-width: 69%;
      margin-bottom: 25px; }
      @media (min-width: 768px) {
        .real-estate .for-who__img--smartphones {
          width: 100%;
          max-width: 450px;
          padding-top: 32px;
          margin-bottom: 74px; } }
      @media (min-width: 1280px) {
        .real-estate .for-who__img--smartphones {
          max-width: 300px;
          padding-top: 0; } }
  @media (min-width: 768px) {
    .real-estate .for-who__box-content {
      width: 80%;
      margin: 0 auto;
      padding: 0; } }
  @media (min-width: 1280px) {
    .real-estate .for-who__box-content {
      width: 40%;
      padding-left: 2%; } }
  .real-estate .for-who__box-heading {
    margin-bottom: 28px;
    font-size: 15px; }
    @media (min-width: 768px) {
      .real-estate .for-who__box-heading {
        font-size: 36px;
        margin-bottom: 61px; } }
    @media (min-width: 1280px) {
      .real-estate .for-who__box-heading {
        font-size: 22px;
        margin-bottom: 39px;
        padding-left: 12px; } }
  @media (min-width: 768px) {
    .real-estate .for-who__box-list {
      padding-left: 46px;
      padding-right: 30px; } }
  @media (min-width: 1280px) {
    .real-estate .for-who__box-list {
      margin-bottom: 43px; } }
  .real-estate .for-who__box-item {
    padding: 25px;
    padding-left: 30px; }
    .real-estate .for-who__box-item:last-child {
      margin-bottom: 0; }
  .real-estate .for-who__item-heading {
    position: relative;
    margin-bottom: 5px;
    color: #333333; }
    .real-estate .for-who__item-heading::before {
      content: '';
      display: block;
      width: 16px;
      height: 11px;
      position: absolute;
      top: 3px;
      left: -30px;
      background-repeat: no-repeat;
      background-position: center; }
      @media (min-width: 768px) {
        .real-estate .for-who__item-heading::before {
          width: 42px;
          height: 30px;
          top: 3px;
          left: -69px; } }
      @media (min-width: 1280px) {
        .real-estate .for-who__item-heading::before {
          width: 35px;
          height: 25px;
          left: -59px; } }
    .real-estate .for-who__item-heading--1-1::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho1-1.svg"); }
    .real-estate .for-who__item-heading--1-2::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho1-2.svg"); }
    .real-estate .for-who__item-heading--1-3::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho1-3.svg"); }
    .real-estate .for-who__item-heading--2-1::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho2-1.svg"); }
    .real-estate .for-who__item-heading--2-2::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho2-2.svg"); }
    .real-estate .for-who__item-heading--2-3::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho2-3.svg"); }
    .real-estate .for-who__item-heading--3-1::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho3-1.svg"); }
    .real-estate .for-who__item-heading--3-2::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho3-2.svg"); }
    .real-estate .for-who__item-heading--3-3::before {
      background-image: url("/static/home/real-estate/for-who/pic-forwho3-3.svg"); }
  .real-estate .for-who__cta {
    text-align: center; }
  .real-estate .for-who__cta-info {
    font-size: 9px;
    color: #666666;
    margin-left: auto;
    margin-right: auto;
    padding-right: 10px;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      .real-estate .for-who__cta-info {
        font-size: 22px;
        margin-bottom: 24px; } }
    @media (min-width: 1280px) {
      .real-estate .for-who__cta-info {
        font-size: 11px;
        text-align: right;
        line-height: 1.3em; } }
  @media (min-width: 1280px) {
    .real-estate .for-who__button {
      padding-left: 25px;
      padding-right: 25px; } }

.real-estate .use-case {
  padding-top: 24px;
  padding-bottom: 22px;
  text-align: center;
  background-color: #F4F4F4; }
  @media (min-width: 768px) {
    .real-estate .use-case {
      padding-top: 77px;
      padding-bottom: 55px; } }
  @media (min-width: 1280px) {
    .real-estate .use-case {
      padding-top: 46px;
      padding-bottom: 46px; } }
  .real-estate .use-case__heading {
    margin-bottom: 27px;
    font-weight: 700; }
    @media (min-width: 768px) {
      .real-estate .use-case__heading {
        margin-bottom: 70px; } }
    @media (min-width: 1280px) {
      .real-estate .use-case__heading {
        margin-bottom: 100px;
        font-size: 30px; } }
  .real-estate .use-case .carousel__img {
    max-width: 650px;
    margin-bottom: 20px; }

.real-estate .tabs-container__img {
  width: 75%;
  margin-right: 30px;
  margin-bottom: 0;
  height: auto !important;
  margin-top: 50px;
  align-self: baseline; }
  .real-estate .tabs-container__img img {
    width: 364px; }

.real-estate .slogan {
  background-image: url("/static/home/real-estate/pic-tryit-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .real-estate .slogan h2 {
    color: white; }
  .real-estate .slogan a {
    text-align: center;
    margin: 0 auto; }
  @media (min-width: 1280px) {
    .real-estate .slogan {
      padding-top: 2px;
      padding-bottom: 24px; } }
  @media (min-width: 1280px) {
    .real-estate .slogan__head {
      margin-bottom: 14px; } }
  .real-estate .slogan__button {
    margin-bottom: 14px; }
  .real-estate .slogan svg {
    margin-top: 20px;
    stroke-width: 1.1px;
    width: 50px;
    height: 30px; }

.real-estate .try-it {
  padding-top: 39px;
  text-align: center;
  background-image: url("/static/home/real-estate/pic-tryit-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; }
  .real-estate .try-it__heading {
    color: #ffffff; }

.real-estate .investment {
  text-align: center;
  padding-top: 63px; }
  @media (min-width: 1280px) {
    .real-estate .investment {
      padding-top: 50px; } }
  @media (min-width: 1280px) {
    .real-estate .investment__mobile {
      display: none; } }
  .real-estate .investment__desktop {
    display: none; }
    @media (min-width: 1280px) {
      .real-estate .investment__desktop {
        display: block; } }
  .real-estate .investment__desktop-heading {
    margin-bottom: 42px;
    font-size: 30px; }
  .real-estate .investment__content {
    width: 45%;
    margin: 50px 0 50px 0 !important;
    padding: 0 !important; }
  .real-estate .investment .carousel {
    border-bottom: 1px solid #CCCCCC;
    padding-bottom: 20px; }
    @media (min-width: 768px) {
      .real-estate .investment .carousel__img {
        margin-bottom: 135px; } }
    .real-estate .investment .carousel__title {
      color: #008aae;
      margin-bottom: 15%;
      font-weight: 700;
      position: relative; }
      @media (min-width: 768px) {
        .real-estate .investment .carousel__title {
          padding-top: 10%;
          margin-bottom: 30px; } }
    .real-estate .investment .carousel__description {
      line-height: 1.35em; }
      .real-estate .investment .carousel__description > li {
        margin-bottom: 12px; }
        @media (min-width: 768px) {
          .real-estate .investment .carousel__description > li {
            margin-bottom: 26px; } }
      @media (min-width: 768px) {
        .real-estate .investment .carousel__description {
          line-height: 1.65em; } }
    .real-estate .investment .carousel__arrows {
      top: 49%; }
  .real-estate .investment__tabs > div:first-child, .real-estate .investment__tabs > div:nth-child(2) {
    max-width: 750px !important; }
  .real-estate .investment__content-container {
    width: 750px;
    border-bottom: 1px solid #CCCCCC;
    padding: 0; }
    .real-estate .investment__content-container > div > div {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      min-height: 350px;
      padding: 0; }
  .real-estate .investment__tab-heading {
    margin-bottom: 15px;
    font-size: 22px; }
  .real-estate .investment__description {
    padding-left: 15px; }
    .real-estate .investment__description > li {
      position: relative;
      margin-bottom: 15px;
      letter-spacing: .8px; }
      .real-estate .investment__description > li::before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        position: relative;
        left: -12px;
        top: 10px;
        background-color: #CCCCCC; }
  .real-estate .investment__img {
    width: 364px; }

.real-estate .carousel {
  position: relative;
  margin: 0 auto; }
  @media (min-width: 1280px) {
    .real-estate .carousel {
      max-width: none;
      text-align: left; } }
  .real-estate .carousel__arrows--investment {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 11%;
    top: 49%; }
    @media (min-width: 768px) {
      .real-estate .carousel__arrows--investment {
        padding: 0 0;
        top: 57%; } }
    .real-estate .carousel__arrows--investment .carousel__prev {
      margin-left: -40px; }
    .real-estate .carousel__arrows--investment .carousel__next {
      margin-right: -40px; }
    .real-estate .carousel__arrows--investment svg {
      width: 15px;
      height: 22px; }
      @media (min-width: 768px) {
        .real-estate .carousel__arrows--investment svg {
          width: 30px;
          height: 46px; } }
      @media (min-width: 1280px) {
        .real-estate .carousel__arrows--investment svg {
          width: 11px;
          height: 16px; } }
  .real-estate .carousel__arrows--usecase {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 11%;
    top: 50%; }
    @media (min-width: 768px) {
      .real-estate .carousel__arrows--usecase {
        padding: 0 0;
        top: 50%; } }
    @media (min-width: 1280px) {
      .real-estate .carousel__arrows--usecase {
        padding: 0;
        top: 37%; } }
    .real-estate .carousel__arrows--usecase .carousel__prev {
      margin-left: -40px; }
    .real-estate .carousel__arrows--usecase .carousel__next {
      margin-right: -40px; }
    .real-estate .carousel__arrows--usecase svg {
      width: 15px;
      height: 22px; }
      @media (min-width: 768px) {
        .real-estate .carousel__arrows--usecase svg {
          width: 30px;
          height: 46px; } }
      @media (min-width: 1280px) {
        .real-estate .carousel__arrows--usecase svg {
          width: 11px;
          height: 16px; } }
  .real-estate .carousel__slides {
    margin: 0 auto;
    max-width: 320px; }
    @media (min-width: 768px) {
      .real-estate .carousel__slides {
        max-width: none; } }
    @media (min-width: 1280px) {
      .real-estate .carousel__slides {
        max-width: 950px; } }
  @media (min-width: 1280px) {
    .real-estate .carousel__slide {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 71px; } }
  .real-estate .carousel__img {
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px; }
    @media (min-width: 768px) {
      .real-estate .carousel__img {
        width: 510px;
        margin-bottom: 46px; } }
  @media (min-width: 1280px) {
    .real-estate .carousel__container {
      margin-left: 72px;
      margin-top: 29px; } }
  .real-estate .carousel__logo {
    width: 50px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 26px; }
    @media (min-width: 768px) {
      .real-estate .carousel__logo {
        width: 116px !important;
        height: auto;
        margin-bottom: 56px; } }
    @media (min-width: 1280px) {
      .real-estate .carousel__logo {
        width: 88px !important;
        height: 42px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 17px; } }
  .real-estate .carousel__title {
    color: #333333;
    font-weight: 400;
    margin-bottom: 32px; }
    @media (min-width: 768px) {
      .real-estate .carousel__title {
        font-size: 36px; } }
    @media (min-width: 1280px) {
      .real-estate .carousel__title {
        font-size: 18px;
        margin-bottom: 8px; } }
  .real-estate .carousel__description {
    line-height: 1.4em;
    margin-bottom: 15px; }
    @media (min-width: 768px) {
      .real-estate .carousel__description {
        font-size: 18px;
        line-height: 2.15em;
        margin-bottom: 55px; } }
    @media (min-width: 1280px) {
      .real-estate .carousel__description {
        font-size: 13px;
        line-height: 1.5em;
        margin-bottom: 20px; } }
  .real-estate .carousel__button {
    margin-bottom: 18px; }
    @media (min-width: 768px) {
      .real-estate .carousel__button {
        margin-bottom: 45px; } }
  .real-estate .carousel .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden; }
  .real-estate .carousel .owl-dots {
    width: 30px;
    bottom: 30%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; }
    @media (min-width: 768px) {
      .real-estate .carousel .owl-dots {
        width: 70px; } }
    @media (min-width: 1280px) {
      .real-estate .carousel .owl-dots {
        width: 30px; } }
    .real-estate .carousel .owl-dots > .owl-dot.active > span {
      background-color: #008aae; }
    .real-estate .carousel .owl-dots > .owl-dot > span {
      display: block;
      width: 5px;
      height: 5px;
      background-color: #CCCCCC;
      border-radius: 50%; }
      @media (min-width: 768px) {
        .real-estate .carousel .owl-dots > .owl-dot > span {
          width: 11px;
          height: 11px; } }
      @media (min-width: 1280px) {
        .real-estate .carousel .owl-dots > .owl-dot > span {
          width: 5px;
          height: 5px; } }

@media (min-width: 768px) {
  .real-estate .investment .carousel .carousel__arrows {
    top: 68%; } }
